//import { store } from "../store/store";
export const Helpers = {
  methods: {
    colorAleatorio() {
      var hexadecimal = new Array(
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F"
      );
      var color_aleatorio = "#";
      for (let i = 0; i < 6; i++) {
        var numPosibilidades = hexadecimal.length - 0;
        var aleat = Math.random() * numPosibilidades;
        aleat = Math.floor(aleat);
        var posarray = parseInt(0) + aleat;
        color_aleatorio += hexadecimal[posarray];
      }
      return color_aleatorio;
    },
    generarToken() {
      const number = Math.floor(Math.random() * (99999 - 1000)) + 1000;
      return Math.random().toString(36).substring(2, 14) + number;
    },
    reemplazarTodos(cadenaAModificar, cadenaBuscada, conQueReemplazar) {
      const searchRegExp = new RegExp(cadenaBuscada, "g");
      return cadenaAModificar.replace(searchRegExp, conQueReemplazar);
    },
    secondsToHM(seconds) {
      seconds = Number(seconds);
      var hora = Math.floor(seconds / 3600);
      var minutos = Math.floor((seconds % 3600) / 60);
      var hDisplay = (hora < 10 ? "0" : "") + (hora > 0 ? hora : "0");
      var mDisplay = (minutos < 10 ? "0" : "") + (minutos > 0 ? minutos : "0");
      return hDisplay + ":" + mDisplay;
    },
    HMToSeconds(hora) {
      var a = hora.split(":");
      var seconds = +a[0] * 60 * 60 + +a[1] * 60;
      return seconds;
    },
    estadoDelPedido(codigo) {
      switch (codigo) {
        case 1:
          return { text: "Solicitado", color: "yellow" };
          break;
        case 2:
          return { text: "Aceptado", color: "blue" };
          break;
        case 3:
          return { text: "En Produccion", color: "orange" };
          break;
        case 4:
          return { text: "En Entrega", color: "purple" };
          break;
        case 5:
          return { text: "Entregado", color: "green" };
          break;
        case 6:
          return { text: "Cancelado", color: "red" };
          break;
        default:
          return { text: "", color: "" };
          break;
      }
    },
    translateErrors(errors) {
      let errorsString = [];
      if (errors) {
        errorsString = errors.map((error) => this.$t(error));
      }
      return errorsString;
    },
    firstLetterToUpperCase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    allFirstLettersUpperCase(string) {
      return string
        .toLowerCase()
        .split(" ")
        .map((word) => this.firstLetterToUpperCase(word))
        .join(" ");
    }
  },
};
